<template>
  <div class="date-picker">
    <VueDatePicker
        :name="name"
        :v-model="date"
        type="time"
        format="HH:MM a"
        :input-class="{ 'm-0': true, 'form-control': true, 'is-danger': error }"
        :style="{ maxWidth: '100%' }"
      />
    <small class="has-error" v-if="error">{{error}}</small>
  </div>
</template>

<script>
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "date-picker",
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.name;
    }
  },
  components: {
    VueDatePicker
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "textInput"
    },
    input: {
      type: Function,
      default: () => {
        console.log("Default funtion triggering in select component");
      }
    },
    error: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      date: null
    }
  },
  watch: {
    date() {
      this.$emit('input', this.name, this.date)
    }
  },
  mounted() {
    this.date = this.value ? this.value : null
  }
};
</script>

<style lang="scss">
.input:focus {
  border-color: $red !important;
  box-shadow: none !important;
}
.has-error {
  color: $red;
}
.is-danger {
  border-color: $red !important;
  box-shadow: none !important;
}
</style>